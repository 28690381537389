import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query/react";

import { ProjectSettings } from "config";

interface CustomError {
  data: {
    message: string;
    error?: boolean;
    field?: string;
    code?: number;
    errors?: {
      location: string;
      msg: string;
      param: string;
      value: any;
      code: number;
    }[];
  };
  status: number;
}

export const BASE_URL =
  ProjectSettings.node_env() === "production"
    ? "https://provider-api.leprecoins.com/api/"
    : "https://api-games.betskilla.com/api/";

interface CustomFetchArgs extends RequestInit {
  url: string;
  params?: Record<string, any>;
  body?: any;
  headers?: HeadersInit;
  responseHandler?:
    | "json"
    | "text"
    | ((response: Response) => Promise<any>)
    | string;
  validateStatus?: (response: Response, body: any) => boolean;
}

export const BaseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    return headers;
  },
  credentials: "include",
});
export const BaseQueryInterceptor = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await BaseQuery(args, api, extraOptions);
    if (result.error?.data === "deleted" || result.error?.data === "OK") {
      return { data: result.error.data, meta: result.meta };
    }

    return result;
  },
  { maxRetries: 0 }
) as BaseQueryFn<string | CustomFetchArgs, unknown, CustomError>;

export const commonApi = createApi({
  reducerPath: "commonApi",
  baseQuery: BaseQueryInterceptor,
  endpoints: (build) => ({}),
});
