import {createSlice} from "@reduxjs/toolkit";
import {IUserState} from "../types/userTypes";
import {userApi} from "../api/userApi";

const initialState: IUserState = {
    user: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetMe(state) {
            state.user = null
        }
    },
    extraReducers: builder => {
        builder
            .addMatcher(
                userApi.endpoints.loginMe.matchFulfilled,
                (state, action) => {
                    state.user = action.payload
                }
            )
            .addMatcher(
                userApi.endpoints.getMyData.matchFulfilled,
                (state, action) => {
                    state.user = action.payload
                }
            )
            .addMatcher(
                userApi.endpoints.logoutMe.matchFulfilled,
                (state, action) => {
                    state.user = null
                }
            )
    }

})

export const {resetMe} = userSlice.actions

export default userSlice.reducer