import {BaseQueryInterceptor} from './commonApi';
import {createApi} from "@reduxjs/toolkit/query/react";
import {
    ICurrency,
    ILanguage,
    IRole,
    IUpdateRole,
    ICreateRole,
    IPermission,
    ICreator,
} from "../types/appTypes";
import {IRequestId, IResponseRows, ISearchByName, ISearchByOrder, ISearchByPage} from "../types";

export const appApi = createApi({
        reducerPath: 'appApi',
        baseQuery: BaseQueryInterceptor,
        endpoints: (build) => ({
            getLanguages: build.mutation<ILanguage[], ISearchByName>({
                query: (obj) => ({
                    url: '/languages',
                    method: 'GET',
                    params: obj.data,
                }),
            }),
            getCurrencies: build.mutation<ICurrency[], ISearchByName>({
                query: (obj) => ({
                    url: '/currencies',
                    method: 'GET',
                    params: obj.data,
                }),
            }),
            getPermissions: build.mutation<IResponseRows<IPermission>, ISearchByName & ISearchByPage>({
                query: (obj) => ({
                    url: '/permissions',
                    method: 'GET',
                    params: obj.data,
                }),
            }),
            getCreators: build.mutation<ICreator[], void>({
                query: () => ({
                    url: '/creators',
                    method: 'GET',
                }),
            }),
            getRoles: build.mutation<IResponseRows<IRole>, ISearchByName & ISearchByPage & ISearchByOrder>({
                query: (obj) => ({
                    url: '/roles',
                    method: 'GET',
                    params: obj.data,
                }),
            }),
            getRolesQ: build.query<IResponseRows<IRole>, ISearchByName & ISearchByPage & ISearchByOrder>({
                query: (obj) => ({
                    url: '/roles',
                    method: 'GET',
                    params: obj.data,
                }),
            }),
            getRoleById: build.mutation<IRole, IRequestId>({
                query: (obj) => ({
                    url: `/roles/${obj.id}`,
                    method: 'GET',
                }),
            }),
            createRole: build.mutation<IRole, ICreateRole>({
                query: (obj) => ({
                    url: '/roles',
                    method: 'POST',
                    body: obj.data,
                }),
            }),
            updateRole: build.mutation<IRole, IUpdateRole>({
                query: (obj) => ({
                    url: `/roles/${obj.id}`,
                    method: 'PATCH',
                    body: obj.data,
                }),
            }),
            deleteRole: build.mutation<string, IRequestId>({
                query: (obj) => ({
                    url: `/roles/${obj.id}`,
                    method: 'DELETE',
                }),
            }),
        })
    })
;

export const {
    useGetLanguagesMutation,
    useGetCurrenciesMutation,
    useGetRolesMutation,
    useGetPermissionsMutation,
    useCreateRoleMutation,
    useGetRoleByIdMutation,
    useDeleteRoleMutation,
    useUpdateRoleMutation,
    useGetCreatorsMutation,
    useGetRolesQQuery,
    useLazyGetRolesQQuery
} = appApi
