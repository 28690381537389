import React from "react";
import "./App.css";
import Routes from "./routes";
import { ThemeProvider } from "styled-components";
import theme from "./constants/theme-constants";
import "./i18n";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
