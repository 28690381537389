import {
  AnyAction,
  Dispatch,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit";
import { appDispatch } from "redux/store";
import { userApi } from "../api/userApi";
import appMiddleware from "./appMiddleware";
import userMiddleware from "./userMiddleware";
import usersMiddleware from "./usersMiddleware";
import { toast } from "react-toastify";
import { t } from "i18next";

const extraMiddlewares: {
  [key: string]: (
    api: MiddlewareAPI,
    dispatch: Dispatch<AnyAction>,
    action: any
  ) => void;
} = {
  appApi: appMiddleware,
  userApi: userMiddleware,
  usersApi: usersMiddleware,
};

const RtkCommonMiddleware: Middleware =
  (api: MiddlewareAPI) => (dispatch) => (action) => {
    const { payload, type, meta, error } = action;
    const currentType = type.split("/")[0];
    const requestName = type.split("/")[1];
    const status = type.split("/")[2];
    const method = meta?.baseQueryMeta?.request?.method || payload?.method;

    const showToast = meta?.arg?.originalArgs?.toast;

    // console.log("payload = ", payload);
    // console.log("type = ", type);
    // console.log("meta = ", meta);
    // console.log("error = ", error);
    // console.log("status = ", status);
    // console.log("method = ", method);
    // console.log("showToast = ", showToast);
    // console.log("requestName = ", requestName);

    if (
      (status !== "fulfilled" && status !== "rejected" && method === "GET") ||
      (status === "rejected" && showToast === "none")
    ) {
      return dispatch(action);
    }

    if (payload?.status === 401) {
      appDispatch(userApi.endpoints.logoutMe.initiate());
      return dispatch(action);
    }

    // Если ошибка содержит имя поля/полей, где произошла ошибка, то уведомление не показывать
    if (
      payload &&
      payload.data &&
      (payload.data?.field || payload.data?.errors) &&
      payload.data?.error
    ) {
      return dispatch(action);
    }

    if (
      status === "rejected" &&
      (showToast === "error" || showToast === undefined)
    ) {
      if (error && requestName === "uploadImage") {
        // @ts-ignore
        toast.error(t(`common.error_code.${payload.data.code}`));
        return dispatch(action);
      }
      if (payload && payload.data && payload.data.code) {
        // @ts-ignore
        toast.error(t(`common.error_code.${payload.data.code}`));
      } else {
        console.log("ne hehe");
        // @ts-ignore
        toast.error(t("common.error_code.202"));
      }
      return dispatch(action);
    }

    if (
      status === "fulfilled" &&
      (showToast === "success" || showToast === "all")
    ) {
      // @ts-ignore
      toast.success(t("common.messages.operation_was_successful"));
      return dispatch(action);
    }

    // if (method === "GET" && status === "rejected" && currentType !== 'transactionApi') {
    //     toast.error(t('common.errors.something_went_wrong'))
    //     return dispatch(action);
    // }

    const exMiddleware = extraMiddlewares[`${currentType}`];
    if (exMiddleware) {
      exMiddleware(api, dispatch, action);
    }

    return dispatch(action);
  };

export default RtkCommonMiddleware;
