import { BaseQueryInterceptor } from "./commonApi";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IUser, IUserLogin } from "../types/userTypes";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: BaseQueryInterceptor,
  endpoints: (build) => ({
    loginMe: build.mutation<IUser, IUserLogin>({
      query: (obj) => ({
        url: "/login",
        method: "POST",
        body: obj.data,
      }),
    }),
    getMyData: build.mutation<IUser, void>({
      query: () => ({
        url: "/auth/me",
        method: "GET",
      }),
    }),
    getMyData2: build.mutation<IUser, void>({
      query: () => ({
        url: "/auth/me",
        method: "GET",
      }),
    }),
    logoutMe: build.mutation<string, void>({
      query: () => ({
        url: "/logout",
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLoginMeMutation,
  useGetMyDataMutation,
  useGetMyData2Mutation,
  useLogoutMeMutation,
} = userApi;
