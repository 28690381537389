import {Theme} from "../interfaces/theme";
import {css} from "styled-components";
import {device} from "./deviceSizes";

export const FontStyle = css`
  color: ${({theme}) => theme.primary.heading};

  font-family: 'Roboto', 'serif';
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  @media (${device.laptop}) {
    font-size: 18px;
    line-height: 21px;
  };

  @media (${device.mobileL}) {
    font-size: 16px;
    line-height: 19px;
  };
`

const theme: Theme = {

    background: {
        main: '#FFFFFF',
        table: '#F6F6F6'
    },
    primary: {
        main: '#2C2C2C',
        heading: '#5F6368',
        light: '#D0D0D0',
        lighter: '#EBEBEB',
        table: '#D9D9D9',
        dirty_white: '#FDFDFD',
        white: '#FFFFFF'
    },
    secondary: {
        main: '#9058A4',
        light: '#BC9DC7',
        disabled: '#D8C8DD',
        light_disabled: '#E6DEE8',
    },
    penumbra: {
        main: 'rgba(95, 99, 104, 0.4)'
    },
    success: {
        main: '#A3F09C',
        disabled: '#D4F3D1',
    },
    error: {
        main: '#F35C5C',
        disabled: '#F5C8C8',
    },
};

export default theme;
