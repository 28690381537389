import {createSlice} from "@reduxjs/toolkit";
import {OrderedMap} from "immutable";
import {ICategory, IGame, IGameSettingsType, IGamesState, IMode} from "../types/gamesTypes";
import {gamesApi} from "../api/gamesApi";

const initialState: IGamesState = {
    game_settings: {
        rows: OrderedMap(),
        count: 0,
        types: []
    },
    games: {
        rows: OrderedMap(),
        count: 0
    },
    categories: {
        rows: OrderedMap(),
        count: 0
    },
    modes: {
        rows: OrderedMap(),
        count: 0
    }
}

const gamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            // GAME SETTINGS
            .addMatcher(
                gamesApi.endpoints.getGameSettings.matchFulfilled,
                (state, action) => {
                    // console.log('reducer getGameSettings = ', action.payload)
                    state.game_settings.rows = getItemsMap<IGameSettingsType>(action.payload.rows)
                    state.game_settings.count = action.payload.count
                }
            )
            .addMatcher(
                gamesApi.endpoints.createGameSettings.matchFulfilled,
                (state, action) => {
                    state.game_settings.rows = state.game_settings.rows.set(action.payload.id, action.payload)
                    state.game_settings.count++
                }
            )
            .addMatcher(
                gamesApi.endpoints.updateGameSettings.matchFulfilled,
                (state, action) => {
                    state.game_settings.rows = state.game_settings.rows.set(action.payload.id, action.payload)
                }
            )
            .addMatcher(
                gamesApi.endpoints.deleteGameSettings.matchFulfilled,
                (state, action) => {
                    // console.log('reducer deleteGameSettings action = ', action)
                    // @ts-ignore
                    state.game_settings.rows = state.game_settings.rows.delete(action.meta.arg.originalArgs.id)
                    state.game_settings.count--
                }
            )
            .addMatcher(
                gamesApi.endpoints.getGameSettingsTypes.matchFulfilled,
                (state, action) => {
                    state.game_settings.types = action.payload
                }
            )

            // GAMES
            .addMatcher(
                gamesApi.endpoints.getGames.matchFulfilled,
                (state, action) => {
                    // console.log('slice getGames = ', action.payload)
                    state.games.rows = getItemsMap<IGame>(action.payload.rows)
                    state.games.count = action.payload.count
                }
            )
            .addMatcher(
                gamesApi.endpoints.getGame.matchFulfilled,
                (state, action) => {
                    // console.log('slice getGame = ', action.payload)
                    if (!state.games.rows.has(action.payload.id)) state.games.count++
                    state.games.rows = state.games.rows.set(action.payload.id, action.payload)
                }
            )
            .addMatcher(
                gamesApi.endpoints.createGame.matchFulfilled,
                (state, action) => {
                    // console.log('slice createGame = ', action.payload)
                    state.games.rows = state.games.rows.set(action.payload.id, action.payload)
                    state.games.count++
                }
            )
            .addMatcher(
                gamesApi.endpoints.updateGame.matchFulfilled,
                (state, action) => {
                    console.log('slice updateGame = ', action.payload)
                    console.log('!state.games.rows.has(action.payload.id) = ', !state.games.rows.has(action.payload.id))
                    if (!state.games.rows.has(action.payload.id)) state.games.count++
                    state.games.rows = state.games.rows.set(action.payload.id, action.payload)
                }
            )
            .addMatcher(
                gamesApi.endpoints.deleteGame.matchFulfilled,
                (state, action) => {
                    console.log('slice deleteGame = ', action.payload)
                    // state.games.rows = state.games.rows.set(action.payload.id, action.payload)
                    state.games.count--
                }
            )

            // FIXME: исправить

            // CATEGORIES
            .addMatcher(
                gamesApi.endpoints.getCategories.matchFulfilled,
                (state, action) => {
                    console.log('slice getCategories = ', action.payload)
                    state.categories.rows = getItemsMap<ICategory>(action.payload)
                    // state.categories.rows = getItemsMap<ICategory>(action.payload.rows)
                    // state.categories.count = action.payload.count
                }
            )

            // MODES
            .addMatcher(
                gamesApi.endpoints.getModes.matchFulfilled,
                (state, action) => {
                    // console.log('slice getModes = ', action.payload)
                    // state.modes.rows = getItemsMap<IMode>(action.payload.rows)
                    state.modes.rows = getItemsMap<IMode>(action.payload)
                    // state.modes.count = action.payload.count
                }
            )

    }
})

export const {} = gamesSlice.actions

export default gamesSlice.reducer


const getItemsMap = <T extends { id: number }>(newItems: T[]) => {
    let newMap: any = OrderedMap();
    for (let i in newItems) {
        // @ts-ignore
        let newItem = newItems[i];
        newMap = newMap.set(newItem.id, newItem)
    }
    return newMap;
}
