import {createAsyncThunk, createSlice, Draft} from "@reduxjs/toolkit";
import {IOperator, IProvidersState, IUsersState, IUserState} from "../types/usersTypes";
import {usersApi} from "../api/usersApi";
import {OrderedMap,} from "immutable";
import {IUser} from "../types/userTypes";
import {t} from "i18next";
import axios from "axios";
import {BASE_URL} from "../api/commonApi";

const initialState: IUsersState = {
    users: <IUserState>{rows: OrderedMap(), count: 0},
    companies: {
        providers: <IProvidersState>{rows: OrderedMap()},
        operators: <IProvidersState>{rows: OrderedMap()}
    }
}


export const uploadUserAvatar = createAsyncThunk(
    'usersApi/uploadUserAvatar',
    async (obj: { data: FormData }) => {
        return await axios({
            url: `${BASE_URL}users/avatar`,
            method: 'POST',
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: obj.data
        })
            .then((pld) => {
                // console.log('uploadUserAvatar then pld = ', pld)
                return {
                    data: pld.data,
                    type: 'usersApi/uploadUserAvatar',
                    method: 'POST',
                    status: pld.status,
                }
            })
            .catch((pld) => {
                // console.log('uploadUserAvatar catch pld = ', pld)
                return {
                    type: 'usersApi/uploadUserAvatar',
                    method: 'POST',
                    status: 'rejected',
                    data: {
                        message: pld?.response?.data?.message || t(`common.errors.uploading_picture`)
                    }
                }
            })
    }
)


const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        clearUsersState(state: any) {
            state.users.rows = state.users.rows.clear()
            state.users.count = 0
        }
    },
    extraReducers: builder => {
        builder
            // users
            .addMatcher(
                usersApi.endpoints.getUsers.matchFulfilled,
                (state, action) => {
                    // console.log('reducer getUsers =', action)
                    // console.log('reducer getUsers payload =', action.payload)

                    if (action.meta.arg.originalArgs.reduxType === 'add') {
                        state.users.rows = state.users.rows.set(action.payload.rows[0].id, action.payload.rows[0])
                        state.users.count = action.payload.count
                    } else {
                        state.users.rows = getUsersMap(action.payload.rows)
                        state.users.count = action.payload.count
                    }


                    // state.users.rows = getUsersMap(action.payload.rows)
                    // state.users.count = action.payload.count
                }
            )
            .addMatcher(
                usersApi.endpoints.updateUser.matchFulfilled,
                (state, action) => {
                    // console.log('reducer updateUser =', action)
                    state.users.rows = state.users.rows.set(action.payload.id, action.payload)
                }
            )
            .addMatcher(
                usersApi.endpoints.createUser.matchFulfilled,
                (state, action) => {
                    // console.log('reducer createUser =', action)
                    state.users.rows = state.users.rows.set(action.payload.id, action.payload)
                    state.users.count++
                }
            )
            .addMatcher(
                usersApi.endpoints.getUser.matchFulfilled,
                (state, action) => {
                    // console.log('reducer getUser =', action)
                    state.users.rows = state.users.rows.set(action.payload.id, action.payload)
                    state.users.count++
                }
            )
            .addMatcher(
                usersApi.endpoints.deleteUser.matchFulfilled,
                (state, action) => {
                    /*@ts-ignore*/
                    state.users.rows = state.users.rows.delete(action.meta.arg.originalArgs.id)
                    state.users.count--
                }
            )

            //operators
            .addMatcher(
                usersApi.endpoints.createOperator.matchFulfilled,
                (state, action) => {
                    state.companies.operators.rows = state.companies.operators.rows.set(action.payload.id, action.payload)
                    state.companies.operators.count++
                }
            )
            .addMatcher(
                usersApi.endpoints.getOperators.matchFulfilled,
                (state, action) => {
                    state.companies.operators.rows = getOperatorsMap(action.payload.rows)
                    state.companies.operators.count = action.payload.count
                }
            )
            .addMatcher(
                usersApi.endpoints.getOperatorsList.matchFulfilled,
                (state, action) => {
                    // console.log('reducer getOperatorsList =', action)
                    state.companies.operators.rows = getOperatorsMap(action.payload)
                    state.companies.operators.count = state.companies.operators.rows.size
                }
            )
            .addMatcher(
                usersApi.endpoints.getOperator.matchFulfilled,
                (state, action) => {
                    state.companies.operators.rows = state.companies.operators.rows.set(action.payload.id, action.payload)
                    state.companies.operators.count++
                }
            )
            .addMatcher(
                usersApi.endpoints.updateOperator.matchFulfilled,
                (state, action) => {
                    state.companies.operators.rows = state.companies.operators.rows.set(action.payload.id, action.payload)
                }
            )
            .addMatcher(
                usersApi.endpoints.deleteOperator.matchFulfilled,
                (state: any, action) => {
                    state.companies.operators.rows = state.companies.operators.rows.delete(action.payload.id)
                }
            )
    }
})

export const {clearUsersState} = usersSlice.actions

export default usersSlice.reducer

const getUsersMap = (newUsers: IUser[]) => {
    let newMap: any = OrderedMap();
    for (let i in newUsers) {
        let newUser = newUsers[i];
        newMap = newMap.set(newUser.id, newUser)
    }
    return newMap;
}

const getOperatorsMap = (newOperators: IOperator[]) => {
    let newMap: any = OrderedMap();
    for (let i in newOperators) {
        let newOperator = newOperators[i];
        newMap = newMap.set(newOperator.id, newOperator)
    }
    return newMap;
}

const mergeUsersOrderedMap = (oldUsers: Map<number, Draft<IUser>>, newUsers: IUser[]) => {
    let newMap: any = oldUsers;
    for (let i in newUsers) {
        let user = newUsers[i];
        newMap = newMap.set(user.id, user)
    }
    return newMap;
}