import {AnyAction, Dispatch, MiddlewareAPI} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {t} from "i18next";

const UsersMiddleware = (api: MiddlewareAPI, dispatch: Dispatch<AnyAction>, action: any) => {
    const {payload, meta, type} = action;
    const method = meta?.baseQueryMeta?.request?.method;
    const status = type.split("/")[2];
    if ((method === "DELETE") && status === "fulfilled") {
        // @ts-ignore
        toast.success(t('common.messages.operation_was_successful'))
    }

    if (status === "rejected") {
        errorMiddleware(api, dispatch, action);
    }
}

const errorMiddleware = (api: MiddlewareAPI, dispatch: Dispatch<AnyAction>, action: any) => {
    const {payload, meta, type} = action;
    const endpoint = meta.arg.endpointName;
    const method = meta?.baseQueryMeta?.request?.method;
    toast.error(` ${payload.data.message}`)

    // if (endpoint === "createUserChild") {
    //     toast(toast(` ${payload.data.message}`))
    //     return
    // }

}
export default UsersMiddleware;
