import {IAppState, ICreator, ICurrency, ILanguage, IPermission, IRole} from "../types/appTypes";
import {createSlice} from "@reduxjs/toolkit";
import {OrderedMap} from "immutable";
import {appApi} from "../api/appApi";

const initialState: IAppState = {
    modal: {
        common: false,
        menu: false
    },
    isLoading: false,
    languages: OrderedMap(),
    currencies: OrderedMap(),
    creators: {
        rows: OrderedMap(),
        count: 0
    },
    roles: {
        rows: OrderedMap(),
        count: 0
    },
    permissions: {
        rows: OrderedMap(),
        count: 0
    },
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        updateMenu(state) {
            state.modal.menu = !state.modal.menu
            state.modal.common = state.modal.menu
        },
        closeAllModal(state) {
            state.modal.common = false
            state.modal.menu = false
        },
    },
    extraReducers: builder => {
        builder
            .addMatcher(
                appApi.endpoints.getLanguages.matchFulfilled,
                (state, action) => {
                    state.languages = getItemsMap(action.payload)
                }
            )
            .addMatcher(
                appApi.endpoints.getCurrencies.matchFulfilled,
                (state, action) => {
                    state.currencies = getItemsMap(action.payload)
                }
            )
            .addMatcher(
                appApi.endpoints.getCreators.matchFulfilled,
                (state, action) => {
                    state.creators.rows = getItemsMap(action.payload)
                    state.creators.count = action.payload.length
                }
            )
            .addMatcher(
                appApi.endpoints.getRoles.matchFulfilled,
                (state, action) => {
                    state.roles.rows = getItemsMap(action.payload.rows)
                    state.roles.count = action.payload.count
                }
            )
            .addMatcher(
                appApi.endpoints.getRolesQ.matchFulfilled,
                (state, action) => {
                    console.log('getRolesQ payload = ', action.payload)
                    state.roles.rows = getItemsMap(action.payload.rows)
                    state.roles.count = action.payload.count
                }
            )
            .addMatcher(
                appApi.endpoints.createRole.matchFulfilled,
                (state, action) => {
                    state.roles.rows = state.roles.rows.set(action.payload.id, action.payload)
                    state.roles.count++
                }
            )
            .addMatcher(
                appApi.endpoints.deleteRole.matchFulfilled,
                (state, action) => {
                    // console.log('reducer deleteRole action = ', action)
                    // @ts-ignore
                    state.roles.rows = state.roles.rows.delete(action.meta.arg.originalArgs)
                    state.roles.count--
                }
            )
            .addMatcher(
                appApi.endpoints.getRoleById.matchFulfilled,
                (state, action) => {
                    state.roles.rows = state.roles.rows.set(action.payload.id, action.payload)
                    state.roles.count++
                }
            )
            .addMatcher(
                appApi.endpoints.getPermissions.matchFulfilled,
                (state, action) => {
                    state.permissions.rows = getItemsMap(action.payload.rows)
                    state.permissions.count = action.payload.count
                }
            )
    }
})

export const {
    updateMenu,
    closeAllModal,
} = appSlice.actions

export default appSlice.reducer

const getItemsMap = (newItems: ILanguage[] | ICurrency | IRole[] | IPermission[] | ICreator[]) => {
    let newMap: any = OrderedMap();
    for (let i in newItems) {
        // @ts-ignore
        let newItem = newItems[i];
        newMap = newMap.set(newItem.id, newItem)
    }
    return newMap;
}