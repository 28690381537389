import React, { lazy, Suspense, useEffect } from "react";
import { useTypedSelector } from "../hooks/useTypedRedux";
import { useGetMyDataMutation } from "../redux/api/userApi";
import PrettyToast from "../ui/PrettyToast";
import {
  useGetLanguagesMutation,
  useGetCurrenciesMutation,
} from "../redux/api/appApi";

const AdminApp = lazy(() => import("./admin-app"));
const GuestApp = lazy(() => import("./guest-app"));

const Router = () => {
  const [getMyData] = useGetMyDataMutation();
  useEffect(() => {
    getMyData();
  }, []);

  const [getLanguages] = useGetLanguagesMutation();
  const [getCurrencies] = useGetCurrenciesMutation();

  const { userId } = useTypedSelector((state) => {
    return {
      userId: state.userSlice.user?.id,
    };
  });

  useEffect(() => {
    if (!!userId) {
      getLanguages({ data: {}, toast: "none" });
      getCurrencies({ data: {}, toast: "none" });
    }
  }, [userId]);

  return (
    <>
      <Suspense fallback={<h1>LOADING...</h1>}>
        {!userId ? <GuestApp /> : <AdminApp />}
      </Suspense>
      <PrettyToast />
    </>
  );
};

export default Router;
