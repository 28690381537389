import {BaseQueryInterceptor} from './commonApi';
import {createApi} from "@reduxjs/toolkit/query/react";
import {IUser} from "../types/userTypes";
import {
    IUpdateUser, ICreateOperator, ICreateUser,
    IGetUsers, IOperator,
    IUpdateOperator, IUpdatePassword,
} from "../types/usersTypes";
import {IRequestId, IResponseRows, ISearchByName, ISearchByOrder, ISearchByPage} from "../types";

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: BaseQueryInterceptor,
    endpoints: (build) => ({

        //users

        getUsers: build.mutation<IResponseRows<IUser>, IGetUsers & ISearchByPage & ISearchByOrder>({
            query: (obj) => ({
                url: '/users',
                method: 'GET',
                params: obj.data,
            }),
        }),
        getUser: build.mutation<IUser, IRequestId>({
            query: (obj) => ({
                url: `/users/${obj.id}`,
                method: 'GET',
            }),
        }),
        updateUser: build.mutation<IUser, IUpdateUser>({
            query: (obj) => ({
                url: `/users/${obj.id}`,
                method: 'PATCH',
                body: obj.data,
            }),
        }),
        createUser: build.mutation<IUser, ICreateUser>({
            query: (obj) => ({
                url: `/signup`,
                method: 'POST',
                body: obj.data,
            }),
        }),
        deleteUser: build.mutation<IUser, IRequestId>({
            query: (obj) => ({
                url: `/users/${obj.id}`,
                method: 'DELETE',
            }),
        }),
        updateUserPassword: build.mutation<IUser, IUpdatePassword>({
            query: (obj) => ({
                url: `/users/password/${obj.id}`,
                method: 'PATCH',
                body: obj.data,
            }),
        }),

        //operators

        createOperator: build.mutation<IOperator, ICreateOperator>({
            query: (obj) => ({
                url: '/operators',
                method: 'POST',
                body: obj.data,
            }),
        }),
        getOperators: build.mutation<IResponseRows<IOperator>, ISearchByName & ISearchByPage & ISearchByOrder>({
            query: (obj) => ({
                url: '/operators',
                method: 'GET',
                params: obj.data,
            }),
        }),
        getOperatorsList: build.mutation<IOperator[], ISearchByName>({
            query: (obj) => ({
                url: '/operators/list',
                method: 'GET',
                params: obj.data,
            }),
        }),
        getOperator: build.mutation<IOperator, IRequestId>({
            query: (obj) => ({
                url: `/operators/${obj.id}`,
                method: 'GET',
            }),
        }),
        updateOperator: build.mutation<IOperator, IUpdateOperator>({
            query: (obj) => ({
                url: `/operators/${obj.id}`,
                method: 'PATCH',
                body: obj.data
            }),
        }),
        deleteOperator: build.mutation<IOperator, IRequestId>({
            query: (obj) => ({
                url: `/operators/${obj.id}`,
                method: 'DELETE',
            }),
        }),
    })
});

export const {
    useGetUsersMutation,
    useGetUserMutation,
    useUpdateUserMutation,
    useCreateUserMutation,
    useDeleteUserMutation,
    useUpdateUserPasswordMutation,
    useGetOperatorsMutation, useGetOperatorMutation, useCreateOperatorMutation,
    useUpdateOperatorMutation, useGetOperatorsListMutation, useDeleteOperatorMutation
} = usersApi
