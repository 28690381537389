import {BaseQueryInterceptor} from './commonApi';
import {createApi} from "@reduxjs/toolkit/query/react";
import {
    DeleteMessage,
    ICategory, ICreateCategory,
    ICreateGame,
    ICreateGameSettings, ICreateMode,
    IGame,
    IGameSettingsType,
    IGetGames, IMode, IUpdateCategory, IUpdateGame,
    IUpdateGameSettings, IUpdateMode
} from "../types/gamesTypes";
import {
    IRequestFormData,
    IRequestId,
    IResponseImageUrl,
    IResponseRows,
    ISearchByName,
    ISearchByOrder,
    ISearchByPage
} from "../types";

export const gamesApi = createApi({
        reducerPath: 'gamesApi',
        baseQuery: BaseQueryInterceptor,
        endpoints: (build) => ({
            getGameSettings: build.mutation<IResponseRows<IGameSettingsType>, ISearchByName & ISearchByPage & ISearchByOrder>({
                query: (obj) => ({
                    url: '/settings',
                    method: 'GET',
                    params: obj.data,
                }),
            }),
            createGameSettings: build.mutation<IGameSettingsType, ICreateGameSettings>({
                query: (obj) => ({
                    url: '/settings',
                    method: 'POST',
                    body: obj.data,
                }),
            }),
            updateGameSettings: build.mutation<IGameSettingsType, IUpdateGameSettings>({
                query: (obj) => ({
                    url: `/settings/${obj.id}`,
                    method: 'PATCH',
                    body: obj.data,
                }),
            }),
            deleteGameSettings: build.mutation<DeleteMessage, IRequestId>({
                query: (obj) => ({
                    url: `/settings/${obj.id}`,
                    method: 'DELETE',
                }),
            }),
            getGameSettingsTypes: build.mutation<string[], ISearchByOrder>({
                query: (obj) => ({
                    url: '/settings/types',
                    method: 'GET',
                    params: obj.data
                }),
            }),


            getGames: build.mutation<IResponseRows<IGame>, IGetGames>({
                query: (obj) => ({
                    url: '/games',
                    method: 'GET',
                    params: obj.data
                })
            }),
            getGame: build.mutation<IGame, IRequestId>({
                query: (obj) => ({
                    url: `/games/${obj.id}`,
                    method: 'GET'
                })
            }),
            createGame: build.mutation<IGame, ICreateGame>({
                query: (obj) => ({
                    url: '/games',
                    method: 'POST',
                    body: obj.data
                })
            }),
            updateGame: build.mutation<IGame, IUpdateGame>({
                query: (obj) => ({
                    url: `/games/${obj.id}`,
                    method: 'PATCH',
                    body: obj.data
                })
            }),
            deleteGame: build.mutation<DeleteMessage, IRequestId>({
                query: (obj) => ({
                    url: `/games/${obj.id}`,
                    method: 'DELETE',
                }),
            }),
            uploadGameImage: build.mutation<IResponseImageUrl, IRequestFormData>({
                query: (obj) => ({
                    url: `/games/image`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data; boundary=----somefixedboundary'
                    },
                    body: obj.data.image
                }),
            }),


            // TODO: попросить Стаса исправить response
            getCategories: build.mutation<ICategory[], ISearchByName>({
                query: (obj) => ({
                    url: '/categories',
                    method: 'GET',
                    params: obj.data
                })
            }),
            getCategory: build.mutation<ICategory, IRequestId>({
                query: (obj) => ({
                    url: `/categories/${obj.id}`,
                    method: 'GET',
                })
            }),
            createCategory: build.mutation<ICategory, ICreateCategory>({
                query: (obj) => ({
                    url: `/categories`,
                    method: 'POST',
                })
            }),
            updateCategory: build.mutation<ICategory, IUpdateCategory>({
                query: (obj) => ({
                    url: `/categories/${obj.id}`,
                    method: 'PATCH',
                    body: obj.data
                })
            }),
            deleteCategory: build.mutation<DeleteMessage, IRequestId>({
                query: (obj) => ({
                    url: `/categories/${obj.id}`,
                    method: 'DELETE',
                })
            }),


            // TODO: попросить Стаса исправить response
            getModes: build.mutation<IMode[], ISearchByName>({
                query: (obj) => ({
                    url: '/modes',
                    method: 'GET',
                    params: obj.data
                })
            }),
            getMode: build.mutation<IMode, IRequestId>({
                query: (obj) => ({
                    url: `/modes/${obj.id}`,
                    method: 'GET',
                })
            }),
            createMode: build.mutation<IMode, ICreateMode>({
                query: () => ({
                    url: '/modes',
                    method: 'PATCH',
                })
            }),
            updateMode: build.mutation<IMode, IUpdateMode>({
                query: (obj) => ({
                    url: `/modes/${obj.id}`,
                    method: 'PATCH',
                    body: obj.data
                })
            }),
            deleteMode: build.mutation<DeleteMessage, IRequestId>({
                query: (obj) => ({
                    url: `/modes/${obj.id}`,
                    method: 'DELETE',
                })
            }),

        })
    })
;

export const {
    useGetGameSettingsMutation,
    useCreateGameSettingsMutation,
    useDeleteGameSettingsMutation,
    useUpdateGameSettingsMutation,
    useGetGameSettingsTypesMutation,
    useGetGamesMutation,
    useGetGameMutation,
    useDeleteGameMutation,
    usePrefetch,
    useUpdateGameMutation,
    useCreateGameMutation,
    useCreateModeMutation,
    useGetModesMutation,
    useCreateCategoryMutation,
    useDeleteCategoryMutation,
    useGetCategoriesMutation,
    useGetCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteModeMutation,
    useGetModeMutation,
    useUpdateModeMutation,
    useUploadGameImageMutation,
} = gamesApi
