import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PrettyToast = () => {
    return (
        <ToastContainer position="bottom-left"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        limit={2}
                        pauseOnHover
                        style={{ color: "#9058A4" }}
        />
    );
};

export default PrettyToast;