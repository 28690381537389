import { createAsyncThunk, createSlice, Draft } from "@reduxjs/toolkit";
import {
  ITransactionsState,
  ITransactionState,
} from "../types/transactionsTypes";
import { ITransaction } from "redux/types/transactionTypes";
import { transactionsApi } from "redux/api/transactionsApi";
import { OrderedMap } from "immutable";
import axios from "axios";
import { BASE_URL } from "../api/commonApi";

const initialState: ITransactionsState = {
  transactions: <ITransactionState>{ rows: OrderedMap(), count: 0 },
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    clearTransactionsState(state: any) {
      state.transactions.rows = state.transactions.rows.clear();
      state.transactions.count = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      transactionsApi.endpoints.getTransactions.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.reduxType === "add") {
          state.transactions.rows = state.transactions.rows.set(
            action.payload.rows[0].id,
            action.payload.rows[0]
          );
          state.transactions.count = action.payload.count;
        } else {
          state.transactions.rows = getTransactionsMap(action.payload.rows);
          state.transactions.count = action.payload.count;
        }
      }
    );
  },
});

export const { clearTransactionsState } = transactionsSlice.actions;

export default transactionsSlice.reducer;

const getTransactionsMap = (newTransactions: ITransaction[]) => {
  let newMap: any = OrderedMap();
  for (let i in newTransactions) {
    let newTransaction = newTransactions[i];
    newMap = newMap.set(newTransaction.id, newTransaction);
  }
  return newMap;
};
