import { BaseQueryInterceptor } from "./commonApi";
import { createApi } from "@reduxjs/toolkit/query/react";
import { ITransaction } from "redux/types/transactionTypes";
import { IGetTransactions } from "redux/types/transactionsTypes";
import {
  IRequestId,
  IResponseRows,
  ISearchByName,
  ISearchByOrder,
  ISearchByPage,
} from "../types";

export const transactionsApi = createApi({
  reducerPath: "transactionsApi",
  baseQuery: BaseQueryInterceptor,
  endpoints: (build) => ({
    getTransactions: build.mutation<
      IResponseRows<ITransaction>,
      IGetTransactions & ISearchByPage & ISearchByOrder
    >({
      query: (obj) => ({
        url: "/transactions",
        method: "GET",
        params: obj.data,
      }),
    }),
  }),
});

export const { useGetTransactionsMutation } = transactionsApi;
