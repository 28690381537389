import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appSlice from "./reducers/appSlice";
import { appApi } from "./api/appApi";
import userSlice from "./reducers/userSlice";
import { userApi } from "./api/userApi";
import usersSlice from "./reducers/usersSlice";
import { usersApi } from "./api/usersApi";
import gamesSlice from "./reducers/gamesSlice";
import { gamesApi } from "./api/gamesApi";
import transactionsSlice from "./reducers/transactionsSlice";
import { transactionsApi } from "./api/transactionsApi";

import RtkCommonMiddleware from "./middlewares";

const rootReducer = combineReducers({
  appSlice,
  userSlice,
  usersSlice,
  gamesSlice,
  transactionsSlice,
  [appApi.reducerPath]: appApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [gamesApi.reducerPath]: gamesApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
});

export let store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      userApi.middleware,
      usersApi.middleware,
      appApi.middleware,
      gamesApi.middleware,
      transactionsApi.middleware,
      RtkCommonMiddleware
    ),
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export const appDispatch = store.dispatch;
